import {ServiceConfig} from "../../app/service/config/config.service";
import {
  AbstractEndpoints, ICountryManagementEndpoints,
  IDashBoardEndpoints,
  IMasterDataConfigurationEndpoints,
  IPageEndpoints, IPagesManagementEndpoints, IRequestDetailsApprovalEmailManagement,
  IRequestManagementEndpoints,
  ISegmentManagementEndpoints,
  IServiceManagementEndpoints,
  IUserManagementEndpoints
} from "./endpoints.abstract";
import {ApprovalOutcome, BusinessArea} from "../../app/enums";

export class Endpoints implements AbstractEndpoints {
  constructor(private config: ServiceConfig) {}

  dashBoard: IDashBoardEndpoints = {
    getDashboardDetails: `${this.config.apiBaseURL}/dashboard/details`,
  };

  masterDataConfiguration: IMasterDataConfigurationEndpoints = {
    //Audit Firm End points
    getAllAuditFirms: `${this.config.apiBaseURL}/audit-firms`,
    getAuditFirmById: (auditFirmId: number)=> `${this.config.apiBaseURL}/audit-firms/${auditFirmId}`,
    createAuditFirm: `${this.config.apiBaseURL}/audit-firms`,
    updateAuditFirm: (auditFirmId: number) => `${this.config.apiBaseURL}/audit-firms/${auditFirmId}`,
    deleteAuditFirm: (auditFirmId: number) => `${this.config.apiBaseURL}/audit-firms/${auditFirmId}`,
    hideAuditFirm: (auditFirmId: number) => `${this.config.apiBaseURL}/audit-firms/hide/${auditFirmId}`,
    showAuditFirm: (auditFirmId: number) => `${this.config.apiBaseURL}/audit-firms/show/${auditFirmId}`,

    //Deliverable End Points
    getAllDeliverables: `${this.config.apiBaseURL}/deliverables`,
    getDeliverableById: (deliverableId: number)=> `${this.config.apiBaseURL}/deliverables/${deliverableId}`,
    createDeliverable: `${this.config.apiBaseURL}/deliverables`,
    updateDeliverable: (deliverableId: number) => `${this.config.apiBaseURL}/deliverables/${deliverableId}`,
    deleteDeliverable: (deliverableId: number) => `${this.config.apiBaseURL}/deliverables/${deliverableId}`,
    hideDeliverable: (deliverableId: number) => `${this.config.apiBaseURL}/deliverables/hide/${deliverableId}`,
    showDeliverable: (deliverableId: number) => `${this.config.apiBaseURL}/deliverables/show/${deliverableId}`,

    //Reason End Points
    getAllReasons: `${this.config.apiBaseURL}/reasons`,
    getReasonById: (reasonId: number)=> `${this.config.apiBaseURL}/reasons/${reasonId}`,
    createReason: `${this.config.apiBaseURL}/reasons`,
    updateReason: (reasonId: number) => `${this.config.apiBaseURL}/reasons/${reasonId}`,
    deleteReason: (reasonId: number) => `${this.config.apiBaseURL}/reasons/${reasonId}`,
    hideReason: (reasonId: number) => `${this.config.apiBaseURL}/reasons/hide/${reasonId}`,
    showReason: (reasonId: number) => `${this.config.apiBaseURL}/reasons/show/${reasonId}`,

    //Service Type End Points
    getAllServiceTypes: `${this.config.apiBaseURL}/service-types`,
    getServiceTypeById: (serviceTypeId: number)=> `${this.config.apiBaseURL}/service-types/${serviceTypeId}`,
    createServiceType: `${this.config.apiBaseURL}/service-types`,
    updateServiceType: (serviceTypeId: number) => `${this.config.apiBaseURL}/service-types/${serviceTypeId}`,
    deleteServiceType: (serviceTypeId: number) => `${this.config.apiBaseURL}/service-types/${serviceTypeId}`,
    hideServiceType: (serviceTypeId: number) => `${this.config.apiBaseURL}/service-types/hide/${serviceTypeId}`,
    showServiceType: (serviceTypeId: number) => `${this.config.apiBaseURL}/service-types/show/${serviceTypeId}`,
  };

  countryManagement: ICountryManagementEndpoints = {
    //Country End Points
    getAllCountries: `${this.config.apiBaseURL}/countries`,
    getCountryById: (countryId: number)=> `${this.config.apiBaseURL}/countries/${countryId}`,
    createCountry: `${this.config.apiBaseURL}/countries`,
    updateCountry: (countryId: number) => `${this.config.apiBaseURL}/countries/${countryId}`,
    deleteCountry: (countryId: number) => `${this.config.apiBaseURL}/countries/${countryId}`,
    hideCountry: (countryId: number) => `${this.config.apiBaseURL}/countries/hide/${countryId}`,
    showCountry: (countryId: number) => `${this.config.apiBaseURL}/countries/show/${countryId}`,

    //Country Currency
    getAllCountryCurrencies: `${this.config.apiBaseURL}/country-currencies`,
    getAllCountryCurrencyById: (countryCurrencyId: number)=> `${this.config.apiBaseURL}/country-currencies/${countryCurrencyId}`,
    createCountryCurrency: `${this.config.apiBaseURL}/country-currencies`,
    updateCountryCurrency: (countryCurrencyId: number) => `${this.config.apiBaseURL}/country-currencies/${countryCurrencyId}`,
    deleteCountryCurrency: (countryCurrencyId: number) => `${this.config.apiBaseURL}/country-currencies/${countryCurrencyId}`,
    hideCountryCurrency: (countryCurrencyId: number) => `${this.config.apiBaseURL}/country-currencies/hide/${countryCurrencyId}`,
    showCountryCurrency: (countryCurrencyId: number) => `${this.config.apiBaseURL}/country-currencies/show/${countryCurrencyId}`,
  };

  pagesManagement: IPagesManagementEndpoints = {
    getAllPageTemplates: `${this.config.apiBaseURL}/page-templates`,
    getPageTemplateByPageTarget: (pageTarget: number) => `${this.config.apiBaseURL}/page-templates/page-target/${pageTarget}`,
    getPageTemplateById: (id: number) => `${this.config.apiBaseURL}/page-templates/${id}`,
    createPageTemplate: `${this.config.apiBaseURL}/page-templates`,
    updatePageTemplate: (id: number) => `${this.config.apiBaseURL}/page-templates/${id}`,
    deletePageTemplate: (id: number) => `${this.config.apiBaseURL}/page-templates/${id}`,
    getActivePageTemplate: (pageTarget: number)=> `${this.config.apiBaseURL}/page-templates/active/${pageTarget}`,
    setActivePageTemplate: (id: number, pageTarget: number)=> `${this.config.apiBaseURL}/page-templates/set-active/${id}/page-target/${pageTarget}`,
  }

  page: IPageEndpoints = {
    //Policy
    createPolicyDocument: `${this.config.apiBaseURL}/policies/file`,
    downloadPolicyDocument: (policyDocumentName: string)=> `${this.config.apiBaseURL}/policies/file/download/${policyDocumentName}`,

    //User Guide
    getAllUserGuides: `${this.config.apiBaseURL}/user-guides/file`,
    GetUserGuideById: (userGuideId: number) => `${this.config.apiBaseURL}/user-guides/file/${userGuideId}`,
    createUserGuide: `${this.config.apiBaseURL}/user-guides/file`,
    updateUserGuide: (userGuideId: number) => `${this.config.apiBaseURL}/user-guides/file/${userGuideId}`,
    deleteUserGuide: (userGuideId: number) => `${this.config.apiBaseURL}/user-guides/file/${userGuideId}`,
    downloadUserGuide: (userGuideDocumentName: string) => `${this.config.apiBaseURL}/user-guides/file/download/${userGuideDocumentName}`
  };

  requestDetailsApprovalEmailManagement: IRequestDetailsApprovalEmailManagement = {
    requestDetailsApprovalEmail: `${this.config.apiBaseURL}/request-details/approval`,
    requestDetailsApprovalEmailCheck: `${this.config.apiBaseURL}/request-details/approval/check`,
    requestDetailsApprovalEmailWithAmendment: `${this.config.apiBaseURL}/request-details/approve-with-amendment`,
    requestDetailsApprovalEmailWithAmendmentCheck: `${this.config.apiBaseURL}/request-details/approve-with-amendment/check`,
  };

  requestManagement: IRequestManagementEndpoints = {
    //Request
    getAllRequests: `${this.config.apiBaseURL}/request-details`,
    getRequestById: (requestId: number)=> `${this.config.apiBaseURL}/request-details/${requestId}`,
    createRequest: `${this.config.apiBaseURL}/request-details`,
    updateRequest: (requestId: number) => `${this.config.apiBaseURL}/request-details/${requestId}`,
    deleteRequest: (requestId: number) => `${this.config.apiBaseURL}/requests/${requestId}`,
    addRequestFileById: (requestId: number) => `${this.config.apiBaseURL}/request-details/file/${requestId}`,
    uploadLoadFile: `${this.config.apiBaseURL}/request-details/file`,
    downloadFile: (fileName: string) => `${this.config.apiBaseURL}/request-details/file/download/${fileName}`,
    getRequestByBusinessArea: `${this.config.apiBaseURL}/request-details/business-area`,
    getRequestByStatus: (status: string) =>`${this.config.apiBaseURL}/request-details/status/${status}`,

    //Temp Request
    getAllTempRequests: `${this.config.apiBaseURL}/temp-request-details`,
    getTempRequestById: (requestId: number)=> `${this.config.apiBaseURL}/temp-request-details/${requestId}`,
    getTempRequestByEmailAddress: (emailAddress: string)=> `${this.config.apiBaseURL}/temp-request-details/email-address/${emailAddress}`,
    createTempRequest: `${this.config.apiBaseURL}/temp-request-details`,
    updateTempRequest: (requestId: number) => `${this.config.apiBaseURL}/temp-request-details/${requestId}`,
    deleteTempRequest: (requestId: number) => `${this.config.apiBaseURL}/temp-request-details/${requestId}`,
    addTempRequestFileById: (requestId: number) => `${this.config.apiBaseURL}/temp-request-details/file/${requestId}`,
    uploadLoadTempRequestFile: `${this.config.apiBaseURL}/temp-request-details/file`,
    downloadTempRequestFile: (fileName: string) => `${this.config.apiBaseURL}/temp-request-details/file/download/${fileName}`,
  };

  serviceManagement: IServiceManagementEndpoints = {
    //Service Type
    getAllServiceTypes: `${this.config.apiBaseURL}/service-types`,
    getServiceTypeById: (serviceTypeId: number)=> `${this.config.apiBaseURL}/service-types/${serviceTypeId}`,
    createServiceType: `${this.config.apiBaseURL}/service-types`,
    updateServiceType: (serviceTypeId: number) => `${this.config.apiBaseURL}/service-types/${serviceTypeId}`,
    deleteServiceType: (serviceTypeId: number) => `${this.config.apiBaseURL}/service-types/${serviceTypeId}`,
    hideServiceType: (serviceTypeId: number) => `${this.config.apiBaseURL}/service-types/hide/${serviceTypeId}`,
    showServiceType: (serviceTypeId: number) => `${this.config.apiBaseURL}/service-types/show/${serviceTypeId}`,

    //Service Category

    getAllServiceCategories: `${this.config.apiBaseURL}/service-categories`,
    getServiceCategoryById: (serviceCategoryId: number)=> `${this.config.apiBaseURL}/service-categories/${serviceCategoryId}`,
    createServiceCategory: `${this.config.apiBaseURL}/service-categories`,
    updateServiceCategory: (serviceCategoryId: number) => `${this.config.apiBaseURL}/service-categories/${serviceCategoryId}`,
    deleteServiceCategory: (serviceCategoryId: number) => `${this.config.apiBaseURL}/service-categories/${serviceCategoryId}`,
    hideServiceCategory: (serviceCategoryId: number) => `${this.config.apiBaseURL}/service-categories/hide/${serviceCategoryId}`,
    showServiceCategory: (serviceCategoryId: number) => `${this.config.apiBaseURL}/service-categories/show/${serviceCategoryId}`,

    //Service Sub Category
    getAllServiceSubCategories: `${this.config.apiBaseURL}/service-sub-categories`,
    getServiceSubCategoryById: (serviceSubCategoryId: number)=> `${this.config.apiBaseURL}/service-sub-categories/${serviceSubCategoryId}`,
    createServiceSubCategory: `${this.config.apiBaseURL}/service-sub-categories`,
    updateServiceSubCategory: (serviceSubCategoryId: number) => `${this.config.apiBaseURL}/service-sub-categories/${serviceSubCategoryId}`,
    deleteServiceSubCategory: (serviceSubCategoryId: number) => `${this.config.apiBaseURL}/service-sub-categories/${serviceSubCategoryId}`,
    hideServiceSubCategory: (serviceSubCategoryId: number) => `${this.config.apiBaseURL}/service-sub-categories/hide/${serviceSubCategoryId}`,
    showServiceSubCategory: (serviceSubCategoryId: number) => `${this.config.apiBaseURL}/service-sub-categories/show/${serviceSubCategoryId}`,
  };

  userManagement: IUserManagementEndpoints = {
    //User End Points
    getAllSystemUsers: `${this.config.apiBaseURL}/system-users`,
    getSystemUserById: (userId: number)=> `${this.config.apiBaseURL}/system-users/${userId}`,
    getSystemUserByEmailAddress: (email: string)=> `${this.config.apiBaseURL}/system-users/email-address/${email}`,
    createSystemUser: `${this.config.apiBaseURL}/system-users`,
    updateSystemUser: (userId: number) => `${this.config.apiBaseURL}/system-users/${userId}`,
    deleteSystemUser: (userId: number) => `${this.config.apiBaseURL}/system-users/${userId}`,
    hideUser: (userId: number) => `${this.config.apiBaseURL}/system-users/hide/${userId}`,
    showUser: (userId: number) => `${this.config.apiBaseURL}/system-users/show/${userId}`,

    //Role End Points
    getAllRoles: `${this.config.apiBaseURL}/roles`,
    getRoleById: (roleId: number)=> `${this.config.apiBaseURL}/roles/${roleId}`,
    createRole: `${this.config.apiBaseURL}/roles`,
    updateRole: (roleId: number) => `${this.config.apiBaseURL}/roles/${roleId}`,
    deleteRole: (roleId: number) => `${this.config.apiBaseURL}/roles/${roleId}`,
  };

  segmentManagement: ISegmentManagementEndpoints = {
    //Segment End Points
    getAllSegments: `${this.config.apiBaseURL}/segments`,
    getSegmentById: (segmentId: number)=> `${this.config.apiBaseURL}/segments/${segmentId}`,
    createSegment: `${this.config.apiBaseURL}/segments`,
    updateSegment: (segmentId: number) => `${this.config.apiBaseURL}/segments/${segmentId}`,
    deleteSegment: (segmentId: number) => `${this.config.apiBaseURL}/segments/${segmentId}`,
    hideSegment: (segmentId: number) => `${this.config.apiBaseURL}/segments/hide/${segmentId}`,
    showSegment: (segmentId: number) => `${this.config.apiBaseURL}/segments/show/${segmentId}`,

    //Sub Segment End Points
    getAllSubSegments: `${this.config.apiBaseURL}/sub-segments`,
    getSubSegmentById: (subSegmentId: number) => `${this.config.apiBaseURL}/sub-segments/${subSegmentId}`,
    createSubSegment: `${this.config.apiBaseURL}/sub-segments`,
    updateSubSegment: (subSegmentId: number) => `${this.config.apiBaseURL}/sub-segments/${subSegmentId}`,
    deleteSubSegment: (subSegmentId: number) => `${this.config.apiBaseURL}/sub-segments/${subSegmentId}`,
    hideSubSegment: (subSegmentId: number) => `${this.config.apiBaseURL}/sub-segments/hide/${subSegmentId}`,
    showSubSegment: (subSegmentId: number) => `${this.config.apiBaseURL}/sub-segments/show/${subSegmentId}`,
  }

}
