export enum PagePaginationNumberNames {
  RequestMadePagination = 'RequestMadePagination',
  AllRequestMadePagination = 'AllRequestMadePagination',
  PagesPagination = 'PagesPagination',
  ReasonsPagination = 'ReasonsPagination',
  DeliverablesPagination = 'DeliverablesPagination',
  UserGuidesPagination = 'UserGuidesPagination',
  SystemUsersPagination = 'SystemUsersPagination',
  RolesPagination = 'RolesPagination',
  SegmentPagination = 'SegmentPagination',
  SubSegmentPagination = 'SubSegmentPagination',
  ServiceTypePagination = 'ServiceTypePagination',
  ServiceCategoryPagination = 'ServiceCategoryPagination',
  ServiceSubCategoryPagination = 'ServiceSubCategoryPagination',
  AuditFirmPagination = 'AuditFirmPagination',
  CountryPagination = 'CountryPagination',
}
