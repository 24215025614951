import {Observable} from "rxjs";
import {CreateSegmentCommand, SegmentResponse, UpdateSegmentCommand} from "../models/segment";
import {CreateSubSegmentCommand, SubSegmentResponse, UpdateSubSegmentCommand} from "../models/sub-segment";

export abstract class SegmentManagementServiceAbstract{
  //Segment
  abstract getAllSegments(): Observable<SegmentResponse[]>;
  abstract getSegmentById(id: number): Observable<SegmentResponse>;
  abstract createSegment(newSegment: CreateSegmentCommand): Observable<SegmentResponse>;
  abstract updateSegment(id: number, updateSegment: UpdateSegmentCommand): Observable<SegmentResponse>;
  abstract deleteSegment(id: number): Observable<number>;
  abstract hideSegment(id: number): Observable<number>;
  abstract showSegment(id: number): Observable<number>;

  //Sub Segment
  abstract getAllSubSegments(): Observable<SubSegmentResponse[]>;
  abstract getSubSegmentById(id: number): Observable<SubSegmentResponse>;
  abstract createSubSegment(newSubSegment: CreateSubSegmentCommand): Observable<SubSegmentResponse>;
  abstract updateSubSegment(id: number, updateSubSegment: UpdateSubSegmentCommand): Observable<SubSegmentResponse>;
  abstract deleteSubSegment(id: number): Observable<number>;
  abstract hideSubSegment(id: number): Observable<number>;
  abstract showSubSegment(id: number): Observable<number>;
}
