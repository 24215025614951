import {Observable} from "rxjs";
import {
  CreateServiceTypeCommand,
  ServiceTypeResponse,
  UpdateServiceTypeCommand
} from "../models/service-type";
import {
  CreateServiceCategoryCommand,
  ServiceCategoryResponse,
  UpdateServiceCategoryCommand
} from "../models/service-category";
import {
  CreateServiceSubCategoryCommand,
  ServiceSubCategoryResponse,
  UpdateServiceSubCategoryCommand
} from "../models/service-sub-category";

export abstract class ServiceManagementServiceAbstract {
  // Service Type CRUD
  abstract getAllServiceTypes(): Observable<ServiceTypeResponse[]>;
  abstract getServiceTypeById(id: number): Observable<ServiceTypeResponse>;
  abstract createServiceType(newServiceType: CreateServiceTypeCommand): Observable<number>;
  abstract updateServiceType(id: number, updateServiceType: UpdateServiceTypeCommand): Observable<number>;
  abstract deleteServiceType(id: number): Observable<number>;
  abstract hideServiceType(id: number): Observable<number>;
  abstract showServiceType(id: number): Observable<number>;

  //Service Category CRUD
  abstract getAllServiceCategories(): Observable<ServiceCategoryResponse[]>;
  abstract getServiceCategoryById(id: number): Observable<ServiceCategoryResponse>;
  abstract createServiceCategory(newServiceCategory: CreateServiceCategoryCommand): Observable<number>;
  abstract updateServiceCategory(id: number, updateServiceCategory: UpdateServiceCategoryCommand): Observable<number>;
  abstract deleteServiceCategory(id: number): Observable<number>;
  abstract hideServiceCategory(id: number): Observable<number>;
  abstract showServiceCategory(id: number): Observable<number>;

  //Service Sub Category CRUD
  abstract getAllServiceSubCategories(): Observable<ServiceSubCategoryResponse[]>;
  abstract getServiceSubCategoryById(id: number): Observable<ServiceSubCategoryResponse>;
  abstract createServiceSubCategory(newServiceSubCategory: CreateServiceSubCategoryCommand): Observable<number>;
  abstract updateServiceSubCategory(id: number, updateServiceSubCategory: UpdateServiceSubCategoryCommand): Observable<number>;
  abstract deleteServiceSubCategory(id: number): Observable<number>;
  abstract hideServiceSubCategory(id: number): Observable<number>;
  abstract showServiceSubCategory(id: number): Observable<number>;
}
