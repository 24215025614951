import {Role} from "../enums";
import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {AbstractServiceAuthentication} from "../service/authentication/authentication.service.abstract";
import {SessionStorage} from "../service/session/session.storage";
import {NoAccessHideModel} from "../model/no-access-hide/no-access-hide.model";

@Directive({
  selector: '[appNoAccessHide]',
})
export class NoAccessHideDirective{
  private _requiredRole: Role[] | undefined = [];
  private _userRole: string[] | undefined = [];
  get requiredRoles(): Role[] | undefined {
    return this._requiredRole;
  }

  get userRoles(): string[] | undefined{
    return this._userRole;
  }

  @Input('appNoAccessHide')
  set requiredRole(value: NoAccessHideModel | undefined) {
    this._requiredRole = value?.requiredRole;
    this._userRole = value?.systemUserRole;
    this.validateAccess();
  }

  constructor(
    // private serviceAuth: AbstractServiceAuthentication,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sessionStorage: SessionStorage
  ) {}

  private validateAccess(): void {
    // Undefined actions implies no restriction - have access without the need for any specific action

    if (!this.requiredRole) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    // Empty actions implies no access - no action will give access
    if (this.requiredRoles?.length === 0) {
      this.viewContainer.clear();
      return;
    }


    if (
      this.requiredRoles?.some((value) => this.sessionStorage.getSystemRoles?.includes(value.toString()))
    ) {
      console.log("role passed")
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
