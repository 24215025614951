import {NavigationRoutes} from "../../helpers/navigation.routes.helper";

export type NavigationMenusType = {
  id: number;
  title: string,
  icon: string,
  link: string,
  hideByRole: string;
  hasSubMenu: boolean | null | undefined;
  subMenuIcon: string | null | undefined;
  subMenuItems: NavigationMenusType[] | null | undefined;
}


export const NavigationMenus = [
  {
    id: 1,
    title: "Home",
    icon: "faSolidHouseChimney",
    link: `/${NavigationRoutes.LandingPage.fullPath}`,
    hideByRole: NavigationRoutes.LandingPage.fullPath,
    hasSubMenu: false,
    subMenuItems: null
  },
  {
    id: 2,
    title: "Policy",
    icon: "faSolidScaleBalanced",
    link: `/${NavigationRoutes.Policy.fullPath}`,
    hideByRole: NavigationRoutes.Policy.fullPath,
    hasSubMenu: false,
    subMenuItems: null
  },
  {
    id: 3,
    title: "User Guides",
    icon: "featherFileText",
    link: `/${NavigationRoutes.UserGuideDownload.fullPath}`,
    hideByRole: NavigationRoutes.UserGuideDownload.fullPath,
    hasSubMenu: false,
    subMenuItems: null
  },
  {
    id: 5,
    title: "Create a Request",
    icon: "faSolidPlus",
    link: `/${NavigationRoutes.MaintainValidatorRequest.fullPath}`,
    hideByRole: NavigationRoutes.MaintainValidatorRequest.fullPath,
    hasSubMenu: false,
    subMenuItems: null
  },
  {
    id: 6,
    title: "Requests Made",
    icon: "faSolidClockRotateLeft",
    link: `/${NavigationRoutes.Request.fullPath}`,
    hideByRole: NavigationRoutes.Request.fullPath,
    hasSubMenu: false,
    subMenuItems: null
  },
  {
    id: 7,
    title: "My Requests",
    icon: "faSolidClockRotateLeft",
    link: `/${NavigationRoutes.RequestMade.fullPath}`,
    hideByRole: NavigationRoutes.RequestMade.fullPath,
    hasSubMenu: false,
    subMenuItems: null
  },
  {
    id: 8,
    title: "Dashboard",
    icon: "matDashboardOutline",
    link: `/${NavigationRoutes.DashBoardHome.fullPath}`,
    hideByRole: NavigationRoutes.DashBoardHome.fullPath,
    hasSubMenu: false,
    subMenuItems: null
  },
  {
    id: 9,
    title: "Admin",
    icon: "faSolidGear",
    link: null,
    hideByRole: NavigationRoutes.Admin.fullPath,
    hasSubMenu: true,
    subMenuIcon: "bootstrapChevronDown",
    subMenuItems: [
      {
        id: 10,
        title: "Pages",
        icon: "faSolidHouseChimney",
        link: `/${NavigationRoutes.HomePageTemplate.fullPath}`,
        hideByRole: NavigationRoutes.HomePageTemplate.fullPath,
        hasSubMenu: false,
        subMenuItems: null
      },
      {
        id: 11,
        title: "Reasons",
        icon: "featherGrid",
        link: `/${NavigationRoutes.Reason.fullPath}`,
        hideByRole: NavigationRoutes.Reason.fullPath,
        hasSubMenu: false,
        subMenuItems: null
      },
      {
        id: 12,
        title: "Deliverable",
        icon: "featherGrid",
        link: `/${NavigationRoutes.Deliverable.fullPath}`,
        hideByRole: NavigationRoutes.Deliverable.fullPath,
        hasSubMenu: false,
        subMenuItems: null
      },
      {
        id: 13,
        title: "User Guides",
        icon: "featherFileText",
        link: `/${NavigationRoutes.UserGuide.fullPath}`,
        hideByRole : NavigationRoutes.UserGuide.fullPath,
        hasSubMenu: false,
        subMenuItems: null
      },
      {
        id: 14,
        title: "Update Policy",
        icon: "featherFileText",
        link: `/${NavigationRoutes.MaintainPolicy.fullPath}`,
        hideByRole: NavigationRoutes.MaintainPolicy.fullPath,
        hasSubMenu: false,
        subMenuItems: null
      },
      {
        id: 15,
        title: "Manage User",
        icon: "featherUser",
        link: `/${NavigationRoutes.SystemUser.fullPath}`,
        hideByRole: NavigationRoutes.SystemUser.fullPath,
        hasSubMenu: false,
        subMenuItems: null
      },
      {
        id: 16,
        title: "Roles",
        icon: "featherUser",
        link: `/${NavigationRoutes.Role.fullPath}`,
        hideByRole: NavigationRoutes.Role.fullPath,
        hasSubMenu: false,
        subMenuItems: null
      },
      {
        id: 17,
        title: "Segments",
        icon: "simpleOpenlayers",
        link: `/${NavigationRoutes.Segment.fullPath}`,
        hideByRole: NavigationRoutes.Segment.fullPath,
        hasSubMenu: false,
        subMenuItems: null
      },
      {
        id: 18,
        title: "Service Types",
        icon: "simpleOpenlayers",
        link: `/${NavigationRoutes.ServiceType.fullPath}`,
        hideByRole: NavigationRoutes.ServiceType.fullPath,
        hasSubMenu: false,
        subMenuItems: null
      },
      {
        id: 19,
        title: "Audit Firm",
        icon: "matTrackChangesOutline",
        link: `/${NavigationRoutes.AuditFirm.fullPath}`,
        hideByRole: NavigationRoutes.AuditFirm.fullPath,
        hasSubMenu: false,
        subMenuItems: null
      },
      {
        id: 20,
        title: "Countries",
        icon: "matLanguageOutline",
        link: `/${NavigationRoutes.Country.fullPath}`,
        hideByRole: NavigationRoutes.Country.fullPath,
        hasSubMenu: false,
        subMenuItems: null
      },
    ]
  }
] as NavigationMenusType[];
