import {Injectable} from "@angular/core";
import {AbstractControl, FormGroup, ValidatorFn} from "@angular/forms";

@Injectable({
  providedIn: 'root',
})
export class ServiceManagementValidation {
  duplicateNameValidator(form: FormGroup, existingItems?: any[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const id = form.controls['id'].value ?? 0;
      const name = form.controls['name'].value;

      if (!name || !existingItems) {
        return null;
      }

      control.markAsTouched();

      const isDuplicate = existingItems.find((obj) => {
        return (
          obj.name.trim().toLowerCase() === name.trim().toLowerCase() &&
          obj.id !== id
        );
      });

      return isDuplicate
        ? {
          duplicate: true,
        }
        : null;
    };
  }
}
